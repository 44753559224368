export default [
  {
    title: 'Phases',
    route: { name: 'projects.phases' },
    icon: 'buildings',
    showCount: true,
    getterName: 'project/phasesTotal'
  },
  {
    title: 'Media',
    route: { name: 'projects.media' },
    icon: 'gallery',
  },
  {
    title: '3D view',
    route: { name: 'projects.ThreeDView' },
    icon: '3dcube',
  },
  {
    title: '@internal agent',
    route: { name: 'projects.agents' },
    icon: 'internal',
  },
  {
    title: 'Online setting',
    route: { name: 'projects.onlineSetting' },
    icon: 'setting',
    children: [
      {
        title: 'Branding',
        route: { name: 'projects.onlineSetting.branding' },
        icon: 'circleIcon',
      },
      {
        title: 'Overview',
        route: { name: 'projects.onlineSetting.description' },
        icon: 'circleIcon',
      },
      {
        title: 'Media',
        route: { name: 'projects.onlineSetting.media' },
        icon: 'circleIcon',
      },
      {
        title: 'Neighborhood',
        route: { name: 'projects.onlineSetting.addresses' },
        icon: 'circleIcon',
      }
    ]
  },
  {
    title: 'Facility',
    route: { name: 'projects.facility' },
    icon: 'task-square',
  },
  {
    title: 'Templates',
    route: { name: 'projects.templates' },
    icon: 'template',
  },
  {
    title: 'Property',
    route: { name: 'projects.properties' },
    icon: 'propertyIcon-2',
  }
]
